import React, {Fragment, useState, useEffect} from 'react';
import {
  Grid,
  TextField,
  Typography,
  Button,
  Dialog,
  Stack,
  Divider,
  Box,
  Backdrop,
  CircularProgress,
  Alert,
  InputAdornment,
  IconButton,
} from '@mui/material';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import DostSeal from '../../assets/phivolcs_seal.png';
import DynaslopeSeal from '../../assets/dynaslope_seal.png';
import {useNavigate} from 'react-router-dom';
import DynaslopeSealMini from '../../assets/dynaslope_seal_mini.png';
import SamarSeal from '../../assets/samar_seal.png';
import ParanasSeal from '../../assets/paranas_seal.png';
import PdrrmcSeal from '../../assets/pdrrmc_seal.png';
import MdrrmoSeal from '../../assets/mdrrmo_seal.png';
import LewcSeal from '../../assets/lewc_seal.png';
import BrgySeal from '../../assets/brgy_seal.png';
import SignInImage from '../../assets/BirdsEyeViewLPA.png';
import SignUp from './Signup';
import ForgotPassword from './ForgotPassword';
import {loginAccount} from '../lipata/api/login_api';

const Signin = () => {
  let navigate = useNavigate();
  const [openSignUp, setOpenSignUp] = useState(false);
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loginMessage, setLoginMessage] = useState(null);
  const [submitButtonState, setSubmitButtonState] = useState(true);
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (username === '' || password === '') {
      setSubmitButtonState(true);
    } else {
      setSubmitButtonState(false);
    }
  }, [username, password, submitButtonState]);

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleOpenSignUp = () => {
    setOpenSignUp(true);
  };

  const handleOpenForgotPassword = () => {
    setOpenForgotPassword(true);
  };

  const handleLogin = () => {
    setOpenBackdrop(!openBackdrop);
    setLoginMessage(null);
    const input = {
      username: username,
      password: password,
    };
    console.log(input);
    loginAccount(input, data => {
      const {status, message} = data;
      if (status) {
        console.log(data);
        const parse_data = JSON.stringify(data.data);
        const {
          profile: {
            designation_details: {id: designation_id},
          },
        } = data.data;
        console.log(designation_id);
        localStorage.setItem('data', parse_data);
        console.log(parse_data);
        if (designation_id === 3 || designation_id === 4) {
          window.location.href = '/opcen';
        } else {
          setLoginMessage('Only MLGU and PLGU stakeholder accounts are allowed to login here.');
        }
      } else {
        setLoginMessage(message);
      }
      setOpenBackdrop(false);
    });
  };

  return (
    <Fragment>
      <Grid container>
        <Grid item xs={12} sm={12}>
          <div
            style={{
              background: '#b1b1b1',
              textAlign: 'center',
              height: 'auto',
              width: 'auto',
              padding: 10,
            }}>
            <img
              src={DostSeal}
              alt="dost-seal-png"
              style={{
                objectFit: 'contain',
                height: 100,
                width: 100,
                marginRight: 14,
              }}
            />
            <img
              src={DynaslopeSealMini}
              alt="dynaslope-seal-mini-png"
              style={{
                objectFit: 'contain',
                height: 100,
                width: 100,
                marginRight: 14,
              }}
            />
            <img
              src={SamarSeal}
              alt="samar-seal-png"
              style={{
                objectFit: 'cover',
                height: 95,
                width: 95,
                marginRight: 14,
              }}
            />
            <img
              src={PdrrmcSeal}
              alt="pdrrmc-seal-png"
              style={{
                objectFit: 'contain',
                height: 110,
                width: 110,
                marginRight: 14,
              }}
            />
            <img
              src={ParanasSeal}
              alt="paranas-seal-png"
              style={{
                objectFit: 'contain',
                height: 95,
                width: 95,
                marginRight: 14,
              }}
            />
            <img
              src={MdrrmoSeal}
              alt="mdrrmo-seal-png"
              style={{
                objectFit: 'contain',
                height: 95,
                width: 95,
                marginRight: 14,
              }}
            />
            <img
              src={BrgySeal}
              alt="brgy-seal-png"
              style={{
                objectFit: 'contain',
                height: 95,
                width: 95,
                marginRight: 14,
              }}
            />
            <img
              src={LewcSeal}
              alt="lewc-seal-png"
              style={{
                objectFit: 'contain',
                height: 95,
                width: 95,
                marginRight: 14,
              }}
            />
          </div>
        </Grid>
        <Grid
          container
          spacing={2}
          style={{
            display: 'flex',
            height: 'auto',
            width: 'auto',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
          }}>
          <Grid
            item
            xs={7}
            sm={4}
            md={7}
            style={{
              backgroundImage: `url(${SignInImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              height: '100%',
              marginTop: 20,
            }}
          />
          <Grid
            item
            xs={5}
            sm={8}
            md={5}
            style={{textAlign: 'center', display: 'flex'}}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} style={{marginTop: 60}}>
                <Typography variant="h3" style={{fontWeight: '500'}}>
                  COMMUNITY-BASED EARLY WARNING SYSTEM FOR LANDSLIDES
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} style={{margin: 20}}>
                <Typography variant="h4" style={{fontWeight: '100'}}>
                  Paranas, Samar
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{margin: 10}}>
                <TextField
                  id="filled-helperText"
                  placeholder="E.g. JuanDelacruz"
                  inputProps={{min: 0, style: {textAlign: 'center'}}}
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  onKeyDown={handleKeyDown}
                  helperText={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{textAlign: 'center'}}>
                      Username
                    </Typography>
                  }
                  variant="outlined"
                  style={{width: '80%'}}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{margin: 10}}>
                <TextField
                  id="filled-helperText"
                  placeholder="**************"
                  inputProps={{min: 0, style: {textAlign: 'center'}}}
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onKeyDown={handleKeyDown}
                  onChange={e => setPassword(e.target.value)}
                  helperText={
                    <Typography
                      variant="caption"
                      display="block"
                      style={{textAlign: 'center'}}>
                      Password
                    </Typography>
                  }
                  variant="outlined"
                  style={{width: '80%'}}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => {
                            setShowPassword(!showPassword);
                          }}
                          onMouseDown={() => {}}
                          edge="end">
                          {showPassword ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {loginMessage && (
                <Grid item xs={12} sm={12} md={12} style={{margin: 10}}>
                  <Typography color={'red'}>{loginMessage}</Typography>
                </Grid>
              )}
              <Grid item xs={12} sm={12} md={12} style={{margin: 15}}>
                <Button
                  disabled={submitButtonState}
                  variant="contained"
                  size="large"
                  onClick={() => {
                    handleLogin();
                  }}
                  style={{width: 300}}>
                  Sign in
                </Button>
              </Grid>
              <Grid item xs={12} sm={12} md={12} style={{margin: 5}}>
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                  justifyContent="center"
                  alignItems="center">
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenForgotPassword}>
                    <Typography style={{fontStyle: 'italic'}}>
                      Forgot Password?
                    </Typography>
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    onClick={handleOpenSignUp}>
                    <Typography style={{fontStyle: 'italic'}}>
                      Waray pa account? Register Didi!
                    </Typography>
                  </Button>
                </Stack>
                <Alert severity="info" style={{marginTop: 15}}>
                  This site uses cookies to analyze non-identifiable web traffic
                  data. DOST-PHIVOLCS is committed to protect and respect your
                  personal data privacy in compliance with the Data Privacy Act
                  of 2012.
                </Alert>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* <Dialog open={openSignUp} maxWidth>
          <SignUp setOpenSignUp={setOpenSignUp} />
        </Dialog> */}

        <SignUp isOpen={openSignUp}
              setIsOpen={setOpenSignUp}
        />

        <Dialog open={openForgotPassword}>
          <ForgotPassword setOpenForgotPassword={setOpenForgotPassword} />
        </Dialog>
        <Grid>
          <Backdrop
            sx={{color: '#fff', zIndex: theme => theme.zIndex.drawer + 1}}
            open={openBackdrop}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default Signin;
